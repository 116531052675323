.ava {
  &-text-white {
    color: white;
    font-family: var(--ava-font-family);
  }

  &-icon-red {
    color: var(--ava-form-label-error-color);
  }
}
