@import 'ava-color';

.ava-form-label:extend(.ava-text) {
  label {
    font-weight: 200;
    font-size: 16px;
  }
}

.ava-form-error:extend(.ava-text) {
  color: var(--ava-form-label-error-color);
}

.ava-form-radio-control:extend(.ava-text-bold) {
}

ava-form-input,
ava-form-select,
ava-form-tel,
ava-form-date,
ava-form-password,
ava-form-card-number {
  nz-form-item.ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 0;
  }
}

ava-form-input .ant-input[disabled] {
  color: rgba(35, 35, 35, 0.85);
}

.ava-form-actions {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  ava-button,
  ava-button-pay {
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
}

[tabindex],
input,
textarea,
select {
  &:focus-visible {
    // Todo
    //border: solid 2px #f83e3e;
    //outline: solid 4px #015fcc;
  }
}
