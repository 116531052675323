.ava {
  // all this gaps just for internals layout of app
  &-gap {
    gap: 4px;
  }

  &-gap-1 {
    gap: 8px;
  }

  &-gap-2 {
    gap: 16px;
  }

  &-row {
    display: flex;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-flex {
    flex: 1;
  }

  &-align-center {
    align-items: center;
  }

  &-align-top {
    align-items: flex-start;
  }

  &-justify-content-start {
    justify-content: flex-start;
  }

  &-justify-content-end {
    justify-content: flex-end;
  }

  &-justify-content-center {
    justify-content: center;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
}
