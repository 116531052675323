.multiblock-component-alert .ant-alert {
  padding: 24px 15px;
  font-size: 24px;
}

.ant-alert-error {
  background-color: rgb(255, 222, 216);
}

.ant-alert-success {
  background-color: rgb(226, 238, 227);
}
