:root {
  --ava-color-gray: #e3e8ef;
  --ava-color-primary: #25538f;
  --ava-form-label-error-color: #ff4d4f;
  --ava-form-payment-icon-color: #3a79bd;

  // form control colour
  --ava-form-control-placeholder-colour: #797676;

  // buttons
  --btn-primary-color: #25538f;
  --btn-primary-color-hover: #0c2b53;
  --btn-primary-disabled-color: #cccccc;

  // icon
  --ava-icon-color: #25538f;
  --ava-icon-hover-color: #0c2b53;

  --ava-login-border: #dedede;
  --ava-login-card-bottom-color: red;
  --ava-login-card-top-color: #00529c;

  --ava-font-family: proxima_nova, sans-serif;

  --ava-membership-level-basic: #25538f;
  --ava-membership-level-plus: #123d74;
  --ava-membership-level-premier: #0c2b53;
  --ava-membership-level--hover: #025aa5;

  // modal header text
  --ava-modal-title: rgba(35, 35, 35, 0.85);
  --ava-breadcrum-bg: rgb(85, 85, 85);
}
