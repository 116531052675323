.flex-microform {
  height: 38px;
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 7.1px 11px;
  color: rgba(35, 35, 35, 0.85);
  font-size: 16px;
  line-height: 1.235;
  background-color: #fff;
  background-image: none;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  display: inline-flex;
}

.flex-microform:focus,
.flex-microform-focused,
.flex-microform:not(.flex-microform-disabled):hover {
  border-color: #26b9eb;
  border-right-width: 2px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(0 160 223 / 20%);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #26b9eb;
  border-right-width: 2px !important;
  z-index: 1;
}
