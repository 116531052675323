@import 'ava-color';
//@import "/apps/multiblocks/src/assets/fonts/proximanova.css";

:root {
  --ava-font-family: proxima_nova, sans-serif;
}

.ava-header-bold {
  font-size: 22px;
  font-weight: 400;
}

.ava-header-sm-bold {
  font-size: 14px;
  font-weight: 600;
}

.ava-text-sm {
  font-size: 14px;
  font-weight: 200;
}

.ava-text {
  font-size: 16px;
  font-weight: 200;
}

.ava-text-bold {
  font-size: 16px;
  font-weight: 400;
}

.ava-link-sm {
  font-size: 14px;
  font-weight: 400;
  color: #036ecd;
}

.ava-congrats {
  font-size: 60px;
  font-weight: 400;
}

.ava-congrats-description {
  font-size: 24px;
  font-weight: 200;
  padding-bottom: 16px;
}

.ava-header-bold,
.ava-header-sm-bold,
.ava-text-sm,
.ava-text,
.ava-text-bold,
.ava-link-sm,
.ava-congrats,
.ava-congrats-description {
  font-family: var(--ava-font-family);
}

.ava-test-color--primary {
  color: #036ecd;
}

.ava-text-white {
  color: white;
}

.ava-render {
  &__paragraph {
    margin: 0;
    padding: 0;
  }
}

.ava-text {
  &-sm {
    font-size: 14px;
    font-family: var(--ava-font-family);
  }
  &-md {
    font-size: 16px;
    font-family: var(--ava-font-family);
  }
  &-lg {
    font-size: 24px;
    font-family: var(--ava-font-family);
  }
  &-xl {
    font-size: 32px;
    font-family: var(--ava-font-family);
  }
  &-xxl {
    font-size: 60px;
    font-family: var(--ava-font-family);
  }
}

.ava-text-bolder {
  font-weight: bolder;
}

.ava-bold {
  &-200 {
    font-weight: 200;
  }
  &-400 {
    font-weight: 400;
  }
  &-600 {
    font-weight: 600;
  }

  &-1 {
    font-weight: 400;
  }

  &-2 {
    font-weight: 600;
  }
}

.ava-p {
  &t {
    &-0 {
      padding-top: 0;
    }
    &-1 {
      padding-top: 0.25rem;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0;
    }
    &-1 {
      padding-bottom: 0.25rem;
    }
    &-2 {
      padding-bottom: 0.5rem;
    }

    &-3 {
      padding-bottom: 0.75rem;
    }

    &-4 {
      padding-bottom: 1rem;
    }
  }

  &l {
    &-0 {
      padding-left: 0;
    }
    &-1 {
      padding-left: 0.25rem;
    }
  }

  &r {
    &-0 {
      padding-right: 0;
    }
    &-1 {
      padding-right: 0.25rem;
    }
  }
}

.ava-m {
  &t {
    &-0 {
      margin-top: 0;
    }

    &-1 {
      margin-top: 0.25rem;
    }

    &-2 {
      margin-top: 0.5rem;
    }

    &-3 {
      margin-top: 0.75rem;
    }

    &-4 {
      margin-top: 1rem;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0;
    }

    &-1 {
      margin-bottom: 0.25rem;
    }

    &-2 {
      margin-bottom: 0.5rem;
    }

    &-3 {
      margin-bottom: 0.75rem;
    }

    &-4 {
      margin-bottom: 1rem;
    }
  }

  &l {
    &-0 {
      margin-left: 0;
    }
    &-1 {
      margin-left: 0.25rem;
    }
  }

  &r {
    &-0 {
      margin-right: 0;
    }
    &-1 {
      margin-right: 0.25rem;
    }
  }
}
