@import 'node_modules/ngx-toastr/toastr.css';

.toast-container ava-notification {
  &.ngx-toastr {
    position: relative;
    width: 384px;
    max-width: calc(100vw - 48px);
    margin-bottom: 16px;
    margin-left: auto;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5715;
    word-wrap: break-word;
    border-radius: 2px;
    box-shadow:
      0 3px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
    background: white;
    color: #000000d9;
  }

  &.ngx-toastr:hover {
    box-shadow:
      0 3px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }

  .toast-title {
    font-weight: 200;
    margin-bottom: 8px;
    color: #000000d9;
    font-size: 16px;
    line-height: 24px;
  }

  .toast-message {
    font-weight: 200;
    font-size: 16px;
    color: #000000d9;
  }

  .toast-close-button {
    color: #777777;
    font-weight: 200;
  }

  &.toast-info,
  &.toast-success,
  &.toast-error,
  &.toast-warning {
    background-color: white;
  }
}
